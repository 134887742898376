const About = () => import('@/pages/website/AboutPage.vue');
const Login = () => import('@/pages/website/LoginPage.vue');
const Signup = () => import('@/pages/website/SignupPage.vue');
const Confirm = () => import('@/pages/website/ConfirmPage.vue');
const AccountSetup = () => import('@/pages/website/AccountSetupPage.vue');
const TwoFactor = () => import('@/pages/website/TwoFactor.vue');
const SummaryPage = () => import('@/pages/website/account/SummaryPage.vue');
const FinishPage = () => import('@/pages/website/account/FinishPage.vue');
const ConfirmedPaymentPage = import('@/pages/website/ConfirmedPaymentPage.vue');
const TrailSignup = () => import('@/pages/website/TrailSignup.vue');

const routes = [
  {
    path: '/',
    name: 'new_account',
    components: {
      default: AccountSetup,
    },
  },
  {
    path: '/about',
    name: 'about',
    components: {
      default: About,
    },
  },
  {
    path: '/w/login',
    name: 'login',
    components: {
      default: Login,
    },
  },
  {
    path: '/w/two_factor',
    name: 'two_factor',
    components: {
      default: TwoFactor,
    },
  },
  {
    path: '/signup',
    name: 'signup',
    components: {
      default: Signup,
    },
  },
  {
    path: '/confirmations/:token',
    name: 'confirm',
    components: {
      default: Confirm,
    },
  },
  {
    path: '/accounts/new',
    name: 'new_account',
    components: {
      default: AccountSetup,
    },
  },
  {
    path: '/accounts/:id/summary',
    name: 'summary_page',
    components: {
      default: SummaryPage,
    },
  },
  {
    path: '/accounts/:id/finish',
    name: 'finish_page',
    components: {
      default: FinishPage,
    },
  },
  {
    path: '/accounts/:id/confirmed',
    name: 'confirmed_payment',
    components: {
      default: ConfirmedPaymentPage,
    },
  },
  {
    path: '/signups/:token/trial',
    name: 'trial-signup',
    components: {
      default: TrailSignup,
    },
  },
];

export default routes;
